import dynamic from 'next/dynamic';

const AboutSection = dynamic(() => import('./about-section'));
const CommunitySection = dynamic(() => import('./community-section'));
const CopyRightComponent = dynamic(() => import('./copyright'));
const CorporateSection = dynamic(() => import('./corporate-section'));
const SocialLinks = dynamic(() => import('./social-links'));
const TrustSection = dynamic(() => import('./trust-section'));

const Footer = () => {
  return (
    <footer className="footer">
      <div className="sv-footer">
        <div className="container">
          {/* four sections */}
          <div className="row">
            <AboutSection></AboutSection>
            <TrustSection></TrustSection>
            <CommunitySection></CommunitySection>
            <CorporateSection></CorporateSection>
          </div>
          {/* social links for follow us */}
          <SocialLinks></SocialLinks>
        </div>
      </div>
      {/* copyright end of the footer */}
      <CopyRightComponent></CopyRightComponent>
    </footer>
  );
};

export default Footer;
